<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import db from "@/firebase/init.js";

import moment from "moment";
import { format } from "date-fns";
/**
 * Form validation component
 */
export default {
  page: {
    title: "VIP",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Liste des discussions",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          active: true,
        },
      ],
      discussions: [],
      sortedDiscussions : [],
      db: db,
      conversations: [],
      nom: "",
      prenom: "",
      idDocument: "",
      usernameSender: "",
      usernameReceiver: "",
      identifiantDiscussion: "",
      totalChat: 0,
      idChat: null,
      modalMessage: false,
      totalMassage: 0,
      idSender: 0,
      searchKey: "",
      senderProfile: null,
      senderFirstName: null,
      senderLastName: null,
      receiverFirstName: null,
      receiverLastName: null,
      receiverProfile: null,
      tokenUser: JSON.parse(localStorage.getItem("user")).tokenSalt,
      niveauUser: JSON.parse(localStorage.getItem("user")).niveau,
      currentSender:0
    };
  },

  async mounted() {
    this.discussions =[]
    moment.locale("fr");
    this.sortedDiscussions =[]
  },

  created() {
    this.db.collection("message").onSnapshot((snap) => {
      this.discussions = [];
      console.log("snap", snap);
      this.getChatRoom();
    });
  },
  methods: {
    //Message liste
    timeElapsed(dateTime) {
      return moment(dateTime).fromNow(true);
    },

    async getProfile(idUser){

      const data = await apiRequest(
                    "POST",
                    "admin/detail-user",
                    { id: idUser },
                    false
                  );

                  if (data && data.data) {
                    console.log("Profile information ", data.data);
                    return `${data.data.personne.firstname} ${data.data.personne.lastname}`

                  }
                  else{
                    return "inconnu"
                  }

    },



    async getChatRoom() {
      this.discussions = []
      this.db
        .collection("messages")
        .get()
        .then((querySnapshot) => {
          querySnapshot.docs.forEach((element) => {
            this.identifiantDiscussion = element.id;
            this.db
              .collection("messages")
              .doc(element.id)
              .collection("chats")
              .orderBy("date", "desc")
              .onSnapshot(async (message) => {
                
                var msg = message.docs[0].data();

                var objIndex = this.discussions.findIndex(
                  (obj) => obj.id == element.id
                );

                this.senderFirstName = "";
                this.senderLastName = "";

                this.receiverFirstName = "";
                this.receiverLastName = "";

                if (objIndex < 0) {
                  const data_sender = await apiRequest(
                    "POST",
                    "admin/detail-user",
                    { id: msg.receiver },
                    false
                  );
                  if (data_sender && data_sender.data) {
                    this.receiverProfile = data_sender.data.userAvatarCode;
                    this.receiverFirstName =
                      data_sender.data.personne.firstname;
                    this.receiverLastName = data_sender.data.personne.lastname;
                  }
                  const data = await apiRequest(
                    "POST",
                    "admin/detail-user",
                    { id: msg.sender },
                    false
                  );

                  if (data && data.data) {
                    this.senderProfile = data.data.profile;
                    this.senderFirstName = data.data.personne.firstname;
                    this.senderLastName = data.data.personne.lastname;
                    if (msg.sender != null) {
                      console.log(
                        "Les profile du sender " +
                          this.senderProfile +
                          "firstname " +
                          this.senderFirstName
                      );
                      this.discussions.push({
                        id: element.id,
                        dateSeule :  msg.date.toDate(),
                        totalMessage: message.docs.length,
                        firstname: data.data.personne.firstname,
                        lastname: data.data.personne.lastname,
                        idSender: element.data().sender,
                        message: msg.text,
                        senderName: msg.senderName,
                        image:
                          data.data.userAvatarCode != null
                            ? `https://rmobility.ovh/admin/image/?codeImage=` +
                              data.data.userAvatarCode +
                              `&token=` +
                              this.tokenUser
                            : "https://cdn-icons-png.flaticon.com/512/1144/1144760.png",

                        imageReceiver:
                          this.receiverProfile != null
                            ? `https://rmobility.ovh/admin/image/?codeImage=` +
                              this.receiverProfile +
                              `&token=` +
                              this.tokenUser
                            : "https://cdn-icons-png.flaticon.com/512/1144/1144760.png",
                        date: 
                        format(
                new Date(msg.date.toDate()),
                "dd-MM-yyyy hh:mm:ss"
              ),
                      });
                  
                   
                   
                    }
                  } else {
                    this.discussions[objIndex].message = msg.text;
                    this.discussions[objIndex].totalMessage =
                      message.docs.length;
                  }
                }
                // get the sender user profile
                this.totalChat = this.discussions.length             
              });
          });
        });
    },

    openChat() {
      alert("clicked");
    },

    async showDiscussion(
      id,
      senderNom,
      SenderPrenom,
    
      idSender,


    ) {

      this.conversations = [];
      this.idChat = id;
      this.idCLient = idSender;

      this.senderFirstName = senderNom;

      this.senderLastName = SenderPrenom;
 
  
      this.usernameSender = this.senderFirstName + " " + this.senderLastName;
     
      this.db
        .collection("messages")
        .doc(id)
        .collection("chats")

        .orderBy("date", "asc")
        .onSnapshot((snap) => {
          snap.docs.forEach(async (element) => {
            let data = element.data();
            let name = await this.getProfile(data.sender)

          
            this.conversations.push({
              name: data.name,
       
              sender: data.sender,
              message: data.text,
              username:name,
    
              date:  format(
                new Date(data.date.toDate()),
                "dd-MM-yyyy hh:mm:ss"
              ),
              
             
            });

            this.currentSender = this.conversations[0].sender
            this.totalMassage = this.conversations.length;
          });

          console.log("les conversations ", this.conversations);
         
          this.modalMessage = true;
          if (this.modalMessage == true) {
            setTimeout(() => {
              this.$refs.current.SimpleBar.getScrollElement().scrollTop =
                this.$refs.current.SimpleBar.getScrollElement().scrollHeight +
                1500;
            }, 500);
          }
        });
    },

    openNewTab(row) {
      window.open(`https://adminrmobility.raynis.co/user/user_info/${row}`, '_blank');
    },

    async trierDiscussion(){
      console.log("wait a minuite ");

      this.sortedDiscussions = await this.discussions.sort((a, b) => new Date(b.dateSeule) - new Date(a.dateSeule))
      console.log(this.sortedDiscussions)
      return this.sortedDiscussions
    }

  },


  computed: {
    searchResult() {
      let messagesList = this.conversations;

      if (this.searchKey != "" && this.searchKey) {
        messagesList = messagesList.filter((item) => {
          return item.message
            ?.toUpperCase()
            .includes(this.searchKey?.toUpperCase());
        });
      }

      return messagesList;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
       
            <div class="d-flex justify-content-between">
              <h2>Discussions location et covoiturage</h2> 
              <button class="flexEd" @click="trierDiscussion()">Trier</button>
            </div>
            <div
              class="row d-flex justify-content-between align-items-center p-2"
            >
              <ol
                class="list-group mb-3 w-100"
                v-for="(room, index) in sortedDiscussions.length !=0 ?sortedDiscussions: discussions"
                :key="index"
              >
                <li class="list-group-item linkDiscussioncontainer">
                  <a
                    href="javascript:void(0);"
                    class="linkDiscussion"
                    v-on:click.prevent="
                      showDiscussion(
                        room.id,
                        room.firstname,
                        room.lastname,
                        room.idSender,
            
                      )
                    "
                  >
                    <div class="media">
                      <img
                        class="rounded-circle mr-3 imageWidth"
                        :src="room.image"
                      />
                      <div class="media-body">
                        <p>
                
                           <span class="nameSender">{{ room.senderName.split(" ")[0] }}</span> 
                           
                          <span class="nameSender"> {{ room.senderName.split(" ")[1] }}</span>
                          <time class="text-muted float-right">{{
                            room.date
                          }}</time>
                        </p>
                        <p class="textSender">{{ room.message }}</p>
                      </div>
                    </div>
                  </a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>

  <b-modal
      ref="my-modal"
      v-model="modalMessage"
      hide-footer
      title="Discussions"
    >
      <div class="user-chat-border">
        <div class="row">
          <div class="col-md-5 col-9">
            <h5 class="nameSender mb-1 ">{{ usernameSender }}</h5>
            <!-- <p class="text-muted mb-0" v-if="online === true">
              <i class="mdi mdi-circle text-success align-middle mr-1"></i>
              en ligne
            </p>
            <p class="text-muted mb-0" v-if="online === false">
              <i class="mdi mdi-circle text-danger align-middle mr-1"></i>
              hors ligne
            </p> -->
          </div>
          <div class="col-md-7 col-3">
            <ul class="list-inline user-chat-nav text-right mb-0">
              <li class="list-inline-item">
                <b-nav-item-dropdown
                  toggle-class="nav-btn"
                  right
                  variant="black"
                  menu-class="dropdown-menu-md p-0"
                >
                  <template v-slot:button-content>
                    <i class="mdi mdi-magnify"></i>
                  </template>
                  <form class="p-2">
                    <div class="search-box">
                      <div class="position-relative">
                        <input
                          type="text"
                          class="form-control rounded bg-light border-0"
                          placeholder="Rechercher..."
                          v-model="searchKey"
                        />
                        <i class="mdi mdi-magnify search-icon"></i>
                      </div>
                    </div>
                  </form>
                </b-nav-item-dropdown>
              </li>
              <li class="list-inline-item d-none d-sm-inline-block">
                <!-- <a
                  class="dropdown-item link-profile"
                  href="#"
                  @click.stop.prevent="goToDetail(idCLient)"
                >
                  <i class="mdi mdi-account"></i
                ></a>

                <a class="dropdown-item" href="#">Clear chat</a>
                    <a class="dropdown-item" href="#">Muted</a>
                    <a class="dropdown-item" href="#">Delete</a>  -->
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="card-body chat_body">
        <div class="chat-widget">
          <div class="chat-conversation">
            <simplebar
              style="max-height: 292px"
              ref="current"
              id="containerElement"
            >
              <ul
                class="list-unstyled mb-0 pr-3"
                v-for="(data, index) in searchResult"
                :key="index"
                :class="{
                    right:
                      `${data.sender}` != currentSender 
                  }"
              >
                <li v-if="!data.text">
                  <div class="conversation-list">
                    <div v-if="data.image" class="chat-avatar">
                      <img :src="`${data.image}`" alt />
                    </div>
                    <div class="ctext-wrap">
                      <div class="conversation-name">
                 

                        <a
                        href="javascript:void(0);"
                        class="text-primary ml-2"
                        @click="
                         openNewTab(data.sender)
                        "
                        v-b-tooltip.hover
                        title="Détail"
                      >
                      <i class="fa fa-user" aria-hidden="true"></i>         {{ data.username }}
                      </a>


                      </div>
                      <div class="ctext-wrap-content">
                        <p class="mb-0">{{ data.message }}</p>
                      </div>
                      <p class="chat-time mb-0">
                        <i class="mdi mdi-clock-outline align-middle mr-1"></i>
                        {{ data.date }}
                        
                      </p>
                    </div>
                  </div>
                </li>

                <li v-if="data.text">
                  <div class="chat-day-title">
                    <span class="title">Today</span>
                  </div>
                </li>
              </ul>
            </simplebar>
          </div>
        </div>
      </div>
    <!--   <div class="p-3 chat-input-section border-top">
        <form @submit.prevent="sendMessage" class="row">
          <div class="col">
            <div>
              <input
                type="text"
                class="form-control rounded chat-input pl-3"
                placeholder="Enter Message..."
                v-model="chat.message"
                :class="{ 'is-invalid': submitted && $v.chat.message.$error }"
              />
              <div
                v-if="submitted && $v.chat.message.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.chat.message.required"
                  >This value is required.</span
                >
              </div>
            </div>
          </div>
          <div class="col-auto">
            <button
              :disabled="$v.chat.$invalid"
              type="submit"
              class="btn btn-primary chat-send w-md waves-effect waves-light"
            >
              <span class="d-none d-sm-inline-block mr-2">Envoyer</span>
              <i class="mdi mdi-send"></i>
            </button>
          </div>
        </form>
      </div> -->
    </b-modal>

  </Layout>
</template>

<style>
thead {
  background: #3aaa35;
}
thead div {
  color: #fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 260px;
  height: 35px;
  top: -50%;
  margin-top: 10px;

  flex: 60%;

  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #d6d2f8;
}
.inputCol {
  position: relative;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}
.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
}
.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: -13%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3aaa35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}
.paging_simple_numbers ul li {
  color: white !important;
}

.paging_simple_numbers ul li button {
  color: #fff !important;
}

.imageWidth {
  width: 35px;
  height: 35px;
}
.linkDiscussion {
  color: #495057 !important;
}

.linkDiscussioncontainer:hover {
  background-color: #F5F5F5;
  height: 80px;
}

.linkDiscussioncontainer {
  background-color: #ffffff;
  height: 80px;
}

.chat-conversation .conversation-list .ctext-wrap-content {
    background-color:#78E378;
    position: relative;
    color: #011627;
}

.nameSender{
font-size: 16px;
color: #011627;
font-weight: 600;
font-family: Inter, serif;
}


.modal-header {
   background:#78E378;
}


.textSender{
  color: #707991;
  font-family: Inter, serif;
  font-size: 14px;

  font-weight: 400;
}



.chat_body{
  overflow-y: scroll;
  height: 40rem;
}

.flexEd{
border:none;
background: #3aaa35;
color: #fff;
width: 70px;
border-radius: 8px;
box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}
</style>
